import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from "./router/index.js"
import zhCn from 'element-plus/es/locale/lang/zh-cn';
import 'normalize.css/normalize.css'

import '../style/headtap.css'
const app =createApp(App)
app.use(router)
app.use(ElementPlus, {
    locale: zhCn,
})

// 引入全局变量
//请求地址
import urls from "../api/api";
app.config.globalProperties.$urls=urls;
//请求方法
import request from "../api/register";
app.config.globalProperties.$request=request;
//消息弹窗提示
import tipsMsg from "../config/Tips";
app.config.globalProperties.$tipsMsg=tipsMsg;
//查询商品列表
import goodsList from "@/page/goods-page/goods";
app.config.globalProperties.$goodsList=goodsList




app.mount('#app')
