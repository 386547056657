<template>
  <div class="ordering">
    <div class="heading">商品列表</div>
    <el-form :inline="true" :model="formInline" size="small" style="display: inline-flex" class="demo-form-inline">
      <!--      <el-form-item label="产品编号">-->
      <!--        <el-input v-model="formInline.shopNum" placeholder="产品编号" clearable />-->
      <!--      </el-form-item>-->
      <el-form-item label="产品编号">
        <el-input v-model="formInline.dishCode" placeholder="产品编码" clearable />
      </el-form-item>

      <el-form-item label="品牌名称">
        <el-input v-model="formInline.brandName" placeholder="品牌名称" clearable />
      </el-form-item>
      <el-form-item label="产品名称">
        <el-input v-model="formInline.shopName" placeholder="产品名称" clearable />
      </el-form-item>
      <el-form-item label="产品类别" style="max-width: 150px">
        <el-select
            v-model="formInline.goodsType"
            placeholder="请选择产品类别"
        >
          <el-option label="饮料" value="1" />
          <el-option label="单品" value="2" />
          <el-option label="散成肉食" value="0031" />
          <el-option label="散成素食" value="0032" />
          <el-option label="散成面包" value="0033" />
          <el-option label="散成坚果" value="0034" />
          <el-option label="散成果脯" value="0035" />
          <el-option label="散成糖果" value="0036" />
          <el-option label="散成果冻" value="0037" />
          <el-option label="散成膨化" value="0038" />
          <el-option label="饼干" value="4" />
          <el-option label="礼盒" value="5" />
        </el-select>
      </el-form-item>
      <el-form-item label="排序方式">
        <el-cascader v-model="formInline.sort" :options="options"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit(formInline)">查询</el-button>

      </el-form-item>
      <el-form-item>
        <router-link to="create-goods">
          <el-button type="success">添加商品</el-button>
        </router-link>
      </el-form-item>
    </el-form>
    <div>
      <!--        //从总数据进行分页读取，如后端返回5条数据，且是第二页的数据，那么该代码则会从(2-1)*5开始读取，到2*10截至-->
      <!--      <el-table :data="tableData.goods_array.slice((tableData.currentPage-1)*tableData.pageSize,tableData.currentPage*tableData.pageSize)" stripe style="width: 100%">-->
      <el-table id="myTable" :data="tableData.goods_array" stripe style="width: 100%;font-size: 15px"
                :row-style="{height:'20px'}" :cell-style="cellStyle" :height="cardDetailsTableHeight">
        <el-table-column v-if="tableData.showFirstCloumn" fixed prop="id" width="auto"   />
<!--        <el-table-column fixed prop="shopNum" label="产品编号" width="auto"  />-->
        <el-table-column fixed prop="dishCode" label="产品编码" width="auto"  />
        <el-table-column prop="brandName" label="品牌名称" width="auto"/>
        <el-table-column prop="shopName" label="商品名称" width="auto"  />
        <el-table-column prop="goodsType" label="产品类别" width="auto" />
        <el-table-column prop="quantity" label="库存数量" width="auto"/>
        <el-table-column prop="purchasePrice" label="采购单价" width="auto"/>
        <el-table-column prop="markDate" label="生产日期" width="auto"/>
        <el-table-column prop="sellByDate" label="保质期(天)" width="auto"/>
        <el-table-column prop="endDate" label="剩余天数" width="auto"  />
        <el-table-column fixed="right" label="操作" min-width="auto">
          <template #default="scope">
            <el-button link type="primary" @click="handleEdit(scope.$index, scope.row)" size="default" >
              <span>编辑</span>
            </el-button>
            <el-button link type="primary" @click="handleDelete(scope.$index, scope.row)" size="default">
              <span>删除</span>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          v-model:current-page="tableData.currentPage"
          v-model:page-size=tableData.pageSize
          :page-sizes="[10,20,60,80,100]"
          :size=size
          :disabled="false"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableData.total"
          @change="pageChange"
          style="position: fixed"
      />
    </div>
  </div>
</template>

<script>
import {reactive, ref,getCurrentInstance,onMounted,toRef} from "vue";
import router from "@/router";
export default {
  setup(){
    const {proxy} = getCurrentInstance();
    const size=ref("large")

    onMounted( () => {
      getCardDetailsTableHeight()
      getGoodList();
    })
    const defaultProps = {
      children: 'children',
      label: 'label',
    }
    const formInline = reactive({
      shopNum: '',
      shopName: '',
      goodsType: '',
      brandName:'',
      dishCode:'',
      sort:''
    })
    const tableData = reactive({
      goods_array:[],
      total:0,
      currentPage:1,
      pageSize:10,
      showFirstCloumn:false
    });
    async function getGoodList() {
      try {
        tableData.goods_array=[]
        let url = proxy.$urls.m().goodsPageList;
        const response = await new proxy.$request(url + "?currentPage=" + tableData.currentPage + "&pageSize=" + tableData.pageSize+"&shopNum="+formInline.shopNum+"&shopName="+formInline.shopName+"&brandName="+formInline.brandName+"&goodsType="+formInline.goodsType+"&sort="+formInline.sort).modeget();
        let type = "success";
        if (response.data.code != 0) {
          type = "error"
        } else {
          tableData.goods_array = response.data.data.content;
          tableData.total = response.data.data.totalAccount;
        }
        new proxy.$tipsMsg(response.data.msg, type).tipmsg()
      } catch (e) {
        new proxy.$tipsMsg("服务器发生错误", 'error').tipmsg()
      }
    }
    const pageChange=(currentPage, pageSize)=>{
      tableData.pageSize=pageSize;
      tableData.currentPage=currentPage
      getGoodList()
    }
    const cellStyle=({row, column, rowIndex, columnIndex})=>{
      if(column.property=='endDate'&&columnIndex==8){
        if(row.endDate>30&&row.endDate<=60){
          return {
            color: 'orange',
            fontWeight: 'bold',
            padding:'0px'
          };
        }else if(row.endDate<=30){
          return {
            color: 'red',
            fontWeight: 'bold',
            padding:'0px'
          };
        }else {
          return {
            padding:'0px'
          };
        }
      }
    }
    const handleEdit=(number,param)=>{
      let paramStr = JSON.stringify(param);
      localStorage.setItem(param.id,paramStr);
      router.push({name:"update-goods",query: {
          param: param.id,
        }})
    }
    const handleDelete = async (number, param) => {
      try {
        tableData.goods_array = []
        let url = proxy.$urls.m().goodsDelete;
        const response = await new proxy.$request(url+"/"+param.id).modeDelete();
        let type = "success";
        if (response.data.code != 0) {
          type = "error"
        }
        await getGoodList();
        new proxy.$tipsMsg(response.data.msg, type).tipmsg()
      } catch (e) {
        new proxy.$tipsMsg("服务器发生错误", 'error').tipmsg()
      }
    }
    // 表格高度
    const cardDetailsTableHeight = ref()

// 动态设置表格高度
    const getCardDetailsTableHeight = () => {
      let getHeightFromBottom = (element, variableHeight) => {
        const elementRect = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const elementHeightFromBottom = windowHeight - elementRect;
        const result = elementHeightFromBottom - variableHeight;
        return result;
      }
      const element = document.getElementById('myTable');
      const variableHeight = 70; // 给定的变量高度 一般留于分页器高度
      cardDetailsTableHeight.value =getHeightFromBottom(element, variableHeight);
    };

    // 监听窗口变化动态设置表格高度
    window.onresize = () => {
      getCardDetailsTableHeight()
    }
    const onSubmit=(params)=>{
      debugger
      formInline.goodsType=params.goodsType;
      formInline.shopNum=params.shopNum;
      formInline.brandName=params.brandName;
      let sort = params.sort;
      formInline.sort=sort[1]==undefined?"":sort[1]
      getGoodList()
      getCardDetailsTableHeight();
    }
    const options = [
      {
        value: '',
        label: '请选择排序方式',
      },
      {
        value: '1',
        label: '库存',
        children: [
          {
            value: '1-1',
            label: '升序',
          },
          {
            value: '1-2',
            label: '降序',
          },
        ],
      },
      {
        value: '2',
        label: '剩余天数',
        children: [
          {
            value: '2-1',
            label: '升序',
          },
          {
            value: '2-2',
            label: '降序',
          }]
      }
    ]
    return {
      tableData,
      pageChange,
      size,
      formInline,
      onSubmit,
      cardDetailsTableHeight,
      cellStyle,
      handleDelete,
      handleEdit,
      options
    }
  }
}
</script>

<style scoped>
.demo-form-inline .el-select {
  --el-select-width: 220px;
}
</style>